<template>
  <div class="m-mega-menu-column">
    <div class=" px-2 px-md-4 px-lg-8">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="m-mega-menu-column__grid">
              <div
                v-for="(column, index) in gridColumns.filter(x => x.length > 0)" 
                :key="'column-' + index"
                class="m-mega-menu-column__grid-column"
              >
                <div 
                  v-for="item in column" 
                  :key="item.id"
                  class="m-mega-menu-column__grid-item"
                >
                  <base-link
                    :link="item.link.url"
                    class="m-mega-menu-column__link -headline"
                    @click.native="setDesktopActiveState(false)"
                  >
                    {{ item.link.name }}
                  </base-link>
    
                  <base-link
                    v-for="child in item.children?.filter(x => !x.hideInNavigation)"
                    :key="child.id"
                    :link="child.link.url"
                    class="m-mega-menu-column__link"
                    @click.native="setDesktopActiveState(false)"
                  >
                    {{ child.link.name }}
                  </base-link>
                </div>
              </div>

              <!-- Promotion cards -->
              <div v-for="(card, index) in promotionCards" :key="'card-' + index" class="m-mega-menu-column__grid-column -end" :style="`grid-column-end: ${5 + index};`">
                <promotion-card :content="card" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import BaseLink from '@/components/atoms/BaseLink';
import PromotionCard from '@/components/molecules/PromotionCard';

export default {
  name: 'MegaMenuColumn',
  components: {
    BaseLink,
    PromotionCard
  },
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    promotionCards: {
      type: Array,
      default: () => []
    }
  },
  
  computed: {
    totalItems () {
      const categories = this.items;
      let totalLength = 0;
      totalLength += categories?.filter(x => !x.hideInNavigation).length;
      categories?.forEach(category => {
          totalLength += category.children?.filter(x => !x.hideInNavigation).length;
      });
      return totalLength;
    },

    columnLength () {
      return this.totalItems > 50 ? 15 : 10;
    },

    gridColumns () {
      const result = [];
      const categories = this.items;
      let currentArray = [];
      let subcategoryCount = 0;
      
      categories.forEach(category => {
          const childrenCount = category.children?.filter(x => !x.hideInNavigation).length ?? 0;
          if (subcategoryCount + childrenCount > this.columnLength) {
              result.push(currentArray);
              currentArray = [];
              subcategoryCount = 0;
          }
          currentArray.push(category);
          subcategoryCount += childrenCount;
      });
      if (currentArray.length > 0) {
          result.push(currentArray);
      }
      return result;
    }
  },
  methods: {
    ...mapMutations('navigation', [
      'setDesktopActiveState'
    ]),
  }
};
</script>
